import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import userActions from '../../api/user/actions';
import Breadcrumbs from '../../routing/Breadcrumbs';

import './Header.scss';

const Header = (props) => {
  const authentication = useSelector((state) => state.authentication);
  const { isSilentlySigningIn, isSignedIn } = authentication;

  const dispatch = useDispatch();

  const [navExpanded, setNavExpanded] = useState(false);
  const toggleNav = (expanded) => setNavExpanded(expanded);
  const openNav = () => setNavExpanded(true);
  const closeNav = () => setNavExpanded(false);

  const signout = (event) => {
    event.preventDefault();
    dispatch(userActions.signout());
  };

  return (
    <div className="Header">
      <Navbar expand="md" onToggle={() => toggleNav()} expanded={navExpanded}>
        <Navbar.Brand>
          <Link to="/" className="logo" onClick={() => closeNav()}>
            Talk Sync
          </Link>
        </Navbar.Brand>
        {!isSilentlySigningIn && (
          <>
            <Navbar.Toggle onClick={() => openNav()} />
            <Navbar.Collapse>
              {isSignedIn && (
                <Nav className="justify-content-start">
                  <LinkContainer onClick={() => closeNav()} to="/dashboard">
                    <Nav.Item as="a">Dashboard</Nav.Item>
                  </LinkContainer>
                  <NavDropdown title="Recordings" id="recordings-dropdown">
                    <LinkContainer exact onClick={() => closeNav()} to="/recordings">
                      <NavDropdown.Item as="a">Recent Recordings</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => closeNav()} to="/unknown-files">
                      <NavDropdown.Item as="a">Unknown / Orphaned Recordings</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => closeNav()} to="/errors">
                      <NavDropdown.Item as="a">Errors</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <LinkContainer onClick={() => closeNav()} to="/linkcodes">
                    <Nav.Item as="a">Linkcodes</Nav.Item>
                  </LinkContainer>
                  <LinkContainer onClick={() => closeNav()} to="/users">
                    <Nav.Item as="a">Users</Nav.Item>
                  </LinkContainer>
                </Nav>
              )}
              <Nav className="ml-auto justify-content-end">
                {isSignedIn && (
                  <LinkContainer to="/signout" onClick={signout}>
                    <Nav.Item as="a">Sign out</Nav.Item>
                  </LinkContainer>
                )}
                {!isSignedIn && (
                  <LinkContainer to="/sigin" onClick={() => closeNav()}>
                    <Nav.Item as="a">Sign In</Nav.Item>
                  </LinkContainer>
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
      <div className="breadcrumbs">
        <Breadcrumbs />
      </div>
    </div>
  );
};

export default Header;
