import React, { Suspense } from 'react';
import { routingTable } from './Routes';
import Loader from '../components/page/Loader';
import { Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const BreadcrumbView = ({ crumbs }) => {
  const { breadcrumbTitle } = useSelector((state) => state.app);

  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <div>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }, key) => {
        if (name === 'Home') {
          return (
            <span key={key}>
              <FontAwesomeIcon icon={faHome} /> {name}
              {key + 1 !== crumbs.length && <> &raquo; </>}
            </span>
          );
        } else if (key + 1 === crumbs.length) {
          return <span key={key}>{breadcrumbTitle || name}</span>;
        } else {
          return (
            <span key={key}>
              <Link to={path}>{name}</Link> &raquo;{' '}
            </span>
          );
        }
      })}
    </div>
  );
};

const Breadcrumbs = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      {routingTable.map(({ path, name, component: Component }, key) => (
        <Route
          exact
          path={path}
          key={key}
          render={(props) => {
            const crumbs = routingTable
              // Get all routes that contain the current one.
              .filter(({ path }) => props.match.path.includes(path))
              // Swap out any dynamic routes with their param values.
              // E.g. "/pizza/:pizzaId" will become "/pizza/1"
              .map(({ path, ...rest }) => ({
                path: Object.keys(props.match.params).length
                  ? Object.keys(props.match.params).reduce(
                      (path, param) => path.replace(`:${param}`, props.match.params[param]),
                      path
                    )
                  : path,
                ...rest,
              }));
            return (
              <div>
                <BreadcrumbView crumbs={crumbs} />
              </div>
            );
          }}
        />
      ))}
    </Suspense>
  );
};

export default Breadcrumbs;
