import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import ChallengedRoute from './ChallengedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import Loader from '../components/page/Loader';

// public pages
const NotFoundPage = lazy(() => import('../containers/common/NotFound'));

// authentication
const SigninPage = lazy(() => import('../containers/authentication/Signin'));
const ForgotPasswordPage = lazy(() => import('../containers/authentication/ForgotPassword'));
const ForgotPasswordResetPage = lazy(() => import('../containers/authentication/ForgotPasswordReset'));

const ChallengePasswordPage = lazy(() => import('../containers/authentication/ChallengePassword'));

// user pages
const UserSettingsPage = lazy(() => import('../containers/private/user/Settings'));
const UserChangePasswordPage = lazy(() => import('../containers/private/user/ChangePassword'));
const UserChangeEmailPage = lazy(() => import('../containers/private/user/ChangeEmail'));
const UserChangeEmailConfirmationPage = lazy(() => import('../containers/private/user/ChangeEmailConfirmation'));

const DashboardPage = lazy(() => import('../containers/private/dashboard/Dashboard'));

const RecordingsPage = lazy(() => import('../containers/private/recordings/Recordings'));
const RecordingPage = lazy(() => import('../containers/private/recordings/Recording'));
const UnknownFilesPage = lazy(() => import('../containers/private/unknown-files/UnknownFiles'));
const UnknownFilePage = lazy(() => import('../containers/private/unknown-files/UnknownFile'));
const LinkcodesPage = lazy(() => import('../containers/private/linkcodes/Linkcodes'));
const LinkcodePage = lazy(() => import('../containers/private/linkcodes/Linkcode'));
const UsersPage = lazy(() => import('../containers/private/users/Users'));
const UserPage = lazy(() => import('../containers/private/users/User'));
const ErrorsPage = lazy(() => import('../containers/private/errors/Errors'));
const ErrorPage = lazy(() => import('../containers/private/errors/Error'));

// prettier-ignore
export const routingTable = [
  { path: '/', name: 'Home', exact: true, redirectTo: '/signin' },
  { path: '/signin', name: 'Sign in', exact: true, unauthenticated: true, component: SigninPage },
  { path: '/signin/forgot', name: 'Forgot Password', exact: true, unauthenticated: true, component: ForgotPasswordPage },
  { path: '/signin/forgot/reset', name: 'Reset Password', exact: true, unauthenticated: true, component: ForgotPasswordResetPage },
  { path: '/signin/new-password', name: 'Reset Password', exact: true, challenged: true, component: ChallengePasswordPage },
  { path: '/dashboard', name: 'Dashboard', exact: true, authenticated: true, component: DashboardPage },
  { path: '/recordings', name: 'Recordings', exact: true, authenticated: true, component: RecordingsPage },
  { path: '/recordings/:recordingId', name: 'Recording', exact: true, authenticated: true, component: RecordingPage },
  { path: '/unknown-files', name: 'Unknown / Orphaned Recordings', exact: true, authenticated: true, component: UnknownFilesPage },
  { path: '/unknown-files/:unknownFileId', name: 'Unknown / Orphaned Recording', exact: true, authenticated: true, component: UnknownFilePage },
  { path: '/linkcodes', name: 'Linkcodes', exact: true, authenticated: true, component: LinkcodesPage },
  { path: '/linkcodes/:linkcodeId', name: 'Linkcode', exact: true, authenticated: true, component: LinkcodePage },
  { path: '/users', name: 'Users', exact: true, authenticated: true, component: UsersPage },
  { path: '/users/:userId', name: 'User', exact: true, authenticated: true, component: UserPage },
  { path: '/errors', name: 'Errors', exact: true, authenticated: true, component: ErrorsPage },
  { path: '/errors/:errorId', name: 'Error', exact: true, authenticated: true, component: ErrorPage },
  { path: '/settings', name: 'Settings', exact: true, authenticated: true, component: UserSettingsPage },
  { path: '/settings/password', name: 'Change your password', exact: true, authenticated: true, component: UserChangePasswordPage },
  { path: '/settings/email', name: 'Change your email', exact: true, authenticated: true, component: UserChangeEmailPage },
  { path: '/settings/email/confirmation', name: 'Confirm your email change', exact: true, authenticated: true, component: UserChangeEmailConfirmationPage },
  { name: 'Page Not Found', component: NotFoundPage },
];

const Routes = () => {
  const redirectOrComponent = (route) => {
    if (route.redirectTo) {
      return <Redirect to={{ pathname: route.redirectTo }} />;
    } else {
      const Component = route.component;
      return <Component />;
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routingTable.map((route, key) => {
          if (route.unauthenticated) {
            return (
              <UnauthenticatedRoute path={route.path} key={key} exact={route.exact}>
                {redirectOrComponent(route)}
              </UnauthenticatedRoute>
            );
          } else if (route.authenticated) {
            return (
              <AuthenticatedRoute path={route.path} key={key} exact={route.exact}>
                {redirectOrComponent(route)}
              </AuthenticatedRoute>
            );
          } else if (route.challenged) {
            return (
              <ChallengedRoute path={route.path} key={key} exact={route.exact}>
                {redirectOrComponent(route)}
              </ChallengedRoute>
            );
          } else {
            return (
              <Route path={route.path} key={key} exact={route.exact}>
                {redirectOrComponent(route)}
              </Route>
            );
          }
        })}
      </Switch>
    </Suspense>
  );
};

export default Routes;
