import { API, Storage } from 'aws-amplify';
import * as helpers from '../../helpers/helper-functions';

const list = (conditions) => {
  const queryString = conditions ? `?${helpers.objectToQueryString(conditions)}` : '';
  return API.get('recordings', '/recordings' + queryString);
};

const listUnknownFiles = (conditions) => {
  const queryString = conditions ? `?${helpers.objectToQueryString(conditions)}` : '';
  return API.get('recordings', '/recordings/unknown-files' + queryString);
};

const create = async (recording) => {
  if (recording.file && recording.file.constructor.name === 'File') {
    const filename = `${Date.now()}-${recording.coverImage.name}`;
    const stored = await Storage.vault.put(filename, recording.file, {
      contentType: recording.file.type,
      level: 'private',
    });
    recording.coverImage = stored.key;
  }
  return API.post('recordings', '/recordings', { body: recording });
};

const get = (id) => API.get('recordings', `/recordings/${id}`);

const download = (recording) => API.get('recordings', `/recordings/${recording.recordingId}/download`);

const update = async (recording) => {
  // update cover image
  if (recording.coverImage && recording.coverImage.constructor.name === 'File') {
    const filename = `${Date.now()}-${recording.coverImage.name}`;
    const stored = await Storage.vault.put(filename, recording.coverImage, {
      contentType: recording.coverImage.type,
      level: 'public',
    });
    recording.coverImage = stored.key;
  }
  // delete old cover image
  if (recording.oldCoverImage) {
    await Storage.vault.remove(recording.oldCoverImage, { level: 'public' });
  }
  // update screenshots
  if (recording.screenshots && recording.screenshots.length > 0) {
    const updatedScreenshots = await Promise.all(
      recording.screenshots.map(async (screenshot) => {
        if (screenshot.constructor.name === 'File') {
          const filename = `${Date.now()}-${screenshot.name}`;
          const stored = await Storage.vault.put(filename, screenshot, {
            contentType: screenshot.type,
            level: 'public',
          });
          return stored.key;
        } else {
          return screenshot;
        }
      })
    );
    recording.screenshots = updatedScreenshots;
  }
  // // delete old screenshots
  if (recording.oldScreenshots && recording.oldScreenshots.length > 0) {
    recording.oldScreenshots.forEach(async (oldScreenshot) => {
      await Storage.vault.remove(oldScreenshot, { level: 'public' });
    });
  }
  // the api handles stripping extra fields out
  return API.put('recordings', '/recordings/' + recording.recordingId, { body: recording });
};

const _delete = async (recording) => {
  return API.del('recordings', `/recordings/${recording.recordingId}`);
};

const recordingService = {
  list,
  listUnknownFiles,
  create,
  get,
  download,
  update,
  delete: _delete,
};

export default recordingService;
