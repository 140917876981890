import { API, Auth } from 'aws-amplify';
import * as helpers from '../../helpers/helper-functions';

const signup = (email, password) => {
  return Auth.signUp(email, password);
};

const confirmSignup = (email, code) => {
  return Auth.confirmSignUp(email, code);
};

const resendSignupCode = (email) => {
  return Auth.resendSignUp(email);
};

const signin = (email, password) => {
  return Auth.signIn(email, password);
};

const signout = () => {
  return Auth.signOut();
};

const currentSession = () => {
  return Auth.currentSession();
};

const forgotPasswordCode = (username) => {
  return Auth.forgotPassword(username);
};

const resendForgotPasswordCode = (email) => {
  return Auth.forgotPassword(email);
};

const forgotPasswordUpdate = (username, code, password) => {
  return Auth.forgotPasswordSubmit(username, code, password);
};

const resendUpdateEmailCode = (email) => {
  return Auth.verifyCurrentUserAttribute('email');
};

const confirmUpdateEmail = (code) => {
  return Auth.verifyCurrentUserAttributeSubmit('email', code);
};

const challengePassword = async (cognitoUser, newPassword) => {
  return Auth.completeNewPassword(cognitoUser, newPassword, []).then(
    (result) => new Promise((resolve, reject) => resolve(result)),
    (error) => new Promise((resolve, reject) => reject(error))
  );
};

const updatePassword = async (oldPassword, newPassword) => {
  const currentUser = Auth.user || (await Auth.currentAuthenticatedUser());
  return Auth.changePassword(currentUser, oldPassword, newPassword).then(
    (result) => new Promise((resolve, reject) => resolve(result)),
    (error) => new Promise((resolve, reject) => reject(error))
  );
};

const list = (conditions) => {
  const queryString = conditions ? `?${helpers.objectToQueryString(conditions)}` : '';
  return API.get('users', '/users' + queryString);
};

const download = (conditions) => API.get('users', `/users.xlsx`);

const get = async (userId) => {
  return API.get('users', '/users/' + userId);
};

const getAuth = async (userId) => {
  return API.get('users', '/users/' + userId + '/auth');
};

const update = async (user) => {
  const request = { body: user };
  return API.put('users', '/users/' + user.userId, request);
};

const _delete = async (user) => {
  // const currentUser = Auth.user || (await Auth.currentAuthenticatedUser());
  // currentUser.deleteUser();
};

const userService = {
  signup,
  confirmSignup,
  resendSignupCode,
  signin,
  signout,
  currentSession,
  forgotPasswordCode,
  resendForgotPasswordCode,
  forgotPasswordUpdate,
  resendUpdateEmailCode,
  confirmUpdateEmail,
  challengePassword,
  updatePassword,
  list,
  download,
  get,
  getAuth,
  update,
  delete: _delete,
};
export default userService;
