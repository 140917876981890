import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import userActions from './api/user/actions';

import Routes from './routing/Routes';
import Header from './components/page/Header';
import Footer from './components/page/Footer';
import Alert from './components/page/Alert';

import './App.scss';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import appActions from './api/app/actions';

const App = () => {
  const authentication = useSelector((state) => state.authentication);
  const { isSilentlySigningIn } = authentication;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(userActions.currentSession());
  }, [dispatch]);

  useEffect(() => {
    return history.listen((location) => {
      dispatch(appActions.setBreadcrumbTitle(null));
    });
  }, [dispatch, history]);

  return (
    !isSilentlySigningIn && (
      <div className="App">
        <Header />
        <Container>
          <Alert />
          <Routes />
        </Container>
        <Footer />
      </div>
    )
  );
};

export default App;
