import unknownFileConstants from './constants';

const initialState = {
  isListing: false,
  isGetting: false,
  isUpdating: false,
  isDeleteing: false,
  items: null,
  lastKey: null,
  item: null,
  error: null,
};

const unknownFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case unknownFileConstants.LIST_REQUEST:
      return {
        ...state,
        isListing: true,
        error: null,
      };
    case unknownFileConstants.LIST_SUCCESS:
      const listItems = state.items ? state.items : [];
      action.result.items.forEach((item) => {
        if (!listItems.map((it) => it.unknownFileId).includes(item.unknownFileId)) listItems.push(item);
      });
      return {
        ...state,
        isListing: false,
        items: listItems,
        lastKey: action.result.lastKey,
      };
    case unknownFileConstants.LIST_FAILURE:
      return {
        ...state,
        isListing: false,
        error: action.error,
      };
    case unknownFileConstants.GET_REQUEST:
      return {
        ...state,
        isGetting: true,
        error: null,
      };
    case unknownFileConstants.GET_SUCCESS:
      return {
        ...state,
        isGetting: false,
        item: action.result,
      };
    case unknownFileConstants.GET_FAILURE:
      return {
        ...state,
        isGetting: false,
        item: null,
        error: action.error,
      };
    case unknownFileConstants.DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloadingRecording: true,
        error: null,
      };
    case unknownFileConstants.DOWNLOAD_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => (item.unknownFileId === action.result.unknownFileId ? action.result : item)),
        isDownloadingRecording: false,
      };
    case unknownFileConstants.DOWNLOAD_FAILURE:
      return {
        ...state,
        isDownloadingRecording: false,
        error: action.error,
      };
    case unknownFileConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case unknownFileConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        items: state.items.map((item) => (item.unknownFileId === action.result.unknownFileId ? action.result : item)),
        item: action.result,
      };
    case unknownFileConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case unknownFileConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleteing: true,
        error: null,
      };
    case unknownFileConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteing: false,
        items: state.items.filter((item) => item.unknownFileId !== action.result.unknownFileId),
        item: null,
      };
    case unknownFileConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleteing: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default unknownFileReducer;
