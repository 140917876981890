import errorConstants from './constants';

const initialState = {
  isListing: false,
  isGetting: false,
  isUpdating: false,
  isDeleteing: false,
  items: null,
  lastKey: null,
  item: null,
  error: null,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case errorConstants.LIST_REQUEST:
      return {
        ...state,
        isListing: true,
        error: null,
      };
    case errorConstants.LIST_SUCCESS:
      const listItems = state.items ? state.items : [];
      action.result.items.forEach((item) => {
        if (!listItems.map((it) => it.errorId).includes(item.errorId)) listItems.push(item);
      });
      return {
        ...state,
        isListing: false,
        items: listItems,
        lastKey: action.result.lastKey,
      };
    case errorConstants.LIST_FAILURE:
      return {
        ...state,
        isListing: false,
        error: action.error,
      };
    case errorConstants.GET_REQUEST:
      return {
        ...state,
        isGetting: true,
        error: null,
      };
    case errorConstants.GET_SUCCESS:
      return {
        ...state,
        isGetting: false,
        item: action.result,
      };
    case errorConstants.GET_FAILURE:
      return {
        ...state,
        isGetting: false,
        item: null,
        error: action.error,
      };
    case errorConstants.DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloadingRecording: true,
        error: null,
      };
    case errorConstants.DOWNLOAD_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => (item.errorId === action.result.errorId ? action.result : item)),
        isDownloadingRecording: false,
      };
    case errorConstants.DOWNLOAD_FAILURE:
      return {
        ...state,
        isDownloadingRecording: false,
        error: action.error,
      };
    case errorConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case errorConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        items: state.items.map((item) => (item.errorId === action.result.errorId ? action.result : item)),
        item: action.result,
      };
    case errorConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case errorConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleteing: true,
        error: null,
      };
    case errorConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteing: false,
        items: state.items.filter((item) => item.errorId !== action.result.errorId),
        item: null,
      };
    case errorConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleteing: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default errorReducer;
