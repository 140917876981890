import linkcodeConstants from './constants';

const initialState = {
  isListing: false,
  isGetting: false,
  isUpdating: false,
  isDeleteing: false,
  items: null,
  lastKey: null,
  item: null,
  error: null,
};

const linkcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case linkcodeConstants.LIST_REQUEST:
      return {
        ...state,
        isListing: true,
        error: null,
      };
    case linkcodeConstants.LIST_SUCCESS:
      const listItems = state.items ? state.items : [];
      action.result.items.forEach((item) => {
        if (!listItems.map((it) => it.linkcodeId).includes(item.linkcodeId)) listItems.push(item);
      });
      return {
        ...state,
        isListing: false,
        items: listItems,
        lastKey: action.result.lastKey,
      };
    case linkcodeConstants.LIST_FAILURE:
      return {
        ...state,
        isListing: false,
        error: action.error,
      };
    case linkcodeConstants.GET_REQUEST:
      return {
        ...state,
        isGetting: true,
        error: null,
      };
    case linkcodeConstants.GET_SUCCESS:
      return {
        ...state,
        isGetting: false,
        item: action.result,
      };
    case linkcodeConstants.GET_FAILURE:
      return {
        ...state,
        isGetting: false,
        item: null,
        error: action.error,
      };
    case linkcodeConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case linkcodeConstants.UPDATE_SUCCESS:
      const updatedItems = state.items
        ? state.items.map((item) =>
            item.linkcodeId === action.result.linkcodeId ? { ...item, ...action.result } : item
          )
        : state.items;
      return {
        ...state,
        isUpdating: false,
        items: updatedItems,
        item: action.result,
      };
    case linkcodeConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case linkcodeConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleteing: true,
        error: null,
      };
    case linkcodeConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteing: false,
        items: state.items.filter((item) => item.linkcodeId !== action.result.linkcodeId),
        item: null,
      };
    case linkcodeConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleteing: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default linkcodeReducer;
