const dev = {
  environment: 'dev',
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'talk-sync-files-dev-downloadsbucket-1v0wj2px7xynb',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://admin-api.dev.talksyncapp.com',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_Wp9NpttDQ',
    APP_CLIENT_ID: '32c9q2l0bq459jraqpq4kvqn25',
    IDENTITY_POOL_ID: 'us-west-2:b381ec09-7eb6-4bef-991b-27ed6e527121',
  },
  google: {
    analyticsCode: '',
  },
  dynamodb: {
    REGION: 'us-west-2',
  },
  cognitoUsers: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_bRwzRvFkv',
  },
  stripe: {
    baseUri: 'https://dashboard.stripe.com/test',
  },
};

const prod = {
  environment: 'prod',
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'talk-sync-files-prod-downloadsbucket-joo5qfaigiqy',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://admin-api.talksyncapp.com',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_JjE14BgOm',
    APP_CLIENT_ID: '1ukchguu54cnhbhndlual11k7h',
    IDENTITY_POOL_ID: 'us-west-2:13ecaf3f-deb7-4afa-878e-d6292e0ffa29',
  },
  google: {
    analyticsCode: '',
  },
  dynamodb: {
    REGION: 'us-west-2',
  },
  cognitoUsers: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_UmVD9cUlJ',
  },
  stripe: {
    baseUri: 'https://dashboard.stripe.com',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
// const config = prod;

const site = {
  SITE_TITLE: 'Talk Sync Admin',
  ALERT_FADEOUT: 7000,
  IMAGE_FORMATS: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'],
  AUDIO_FORMATS: ['audio/mp3', 'audio/mp4'],
};

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
  site,
};
