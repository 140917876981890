import recordingConstants from './constants';

const initialState = {
  isListing: false,
  isGetting: false,
  isUpdating: false,
  isDeleteing: false,
  items: null,
  lastKey: null,
  item: null,
  error: null,
};

const recordingReducer = (state = initialState, action) => {
  switch (action.type) {
    case recordingConstants.LIST_REQUEST:
      return {
        ...state,
        isListing: true,
        error: null,
      };
    case recordingConstants.LIST_SUCCESS:
      const listItems = state.items ? state.items : [];
      action.result.items.forEach((item) => {
        const itemIndex = listItems.findIndex((listItem) => listItem.recordingId === item.recordingId);
        if (itemIndex > -1) {
          listItems[itemIndex] = { ...listItems[itemIndex], ...item };
        } else {
          listItems.push(item);
        }
      });
      return {
        ...state,
        isListing: false,
        items: listItems,
        lastKey: action.result.lastKey,
      };
    case recordingConstants.LIST_FAILURE:
      return {
        ...state,
        isListing: false,
        error: action.error,
      };
    case recordingConstants.GET_REQUEST:
      return {
        ...state,
        isGetting: true,
        error: null,
      };
    case recordingConstants.GET_SUCCESS:
      return {
        ...state,
        isGetting: false,
        item: action.result,
      };
    case recordingConstants.GET_FAILURE:
      return {
        ...state,
        isGetting: false,
        item: null,
        error: action.error,
      };
    case recordingConstants.DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloadingRecording: true,
        error: null,
      };
    case recordingConstants.DOWNLOAD_SUCCESS:
      return {
        ...state,
        isDownloadingRecording: false,
      };
    case recordingConstants.DOWNLOAD_FAILURE:
      return {
        ...state,
        isDownloadingRecording: false,
        error: action.error,
      };
    case recordingConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case recordingConstants.UPDATE_SUCCESS:
      const updatedItem = { ...state.item, ...action.result };
      return {
        ...state,
        isUpdating: false,
        items: state.items
          ? state.items.map((item) => (item.recordingId === action.result.recordingId ? updatedItem : item))
          : [updatedItem],
        item: updatedItem,
      };
    case recordingConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case recordingConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleteing: true,
        error: null,
      };
    case recordingConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteing: false,
        items: state.items.filter((item) => item.recordingId !== action.result.recordingId),
        item: null,
      };
    case recordingConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleteing: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default recordingReducer;
