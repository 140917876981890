import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import alert from './alert/reducer';
import app from './app/reducer';
import authentication from './authentication/reducer';
import error from './error/reducer';
import recording from './recording/reducer';
import linkcode from './linkcode/reducer';
import unknownFile from './unknown-file/reducer';
import user from './user/reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    alert,
    app,
    authentication,
    error,
    recording,
    linkcode,
    unknownFile,
    user,
  });

export default rootReducer;
