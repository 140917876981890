import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import './Loader.scss';

const Loader = ({ big = false, loadingText = 'Loading', isLoading, showSpinner = false, ...props }) => (
  <>
    {isLoading && (
      <div className={big ? 'Loader big text-center' : 'Loader text-center'}>
        <FontAwesomeIcon icon={faSyncAlt} /> {loadingText}...
      </div>
    )}
  </>
);
export default Loader;
