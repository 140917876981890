const errorConstants = {
  LIST_REQUEST: 'ERRORS_LIST_REQUEST',
  LIST_SUCCESS: 'ERRORS_LIST_SUCCESS',
  LIST_FAILURE: 'ERRORS_LIST_FAILURE',

  GET_REQUEST: 'ERRORS_GET_REQUEST',
  GET_SUCCESS: 'ERRORS_GET_SUCCESS',
  GET_FAILURE: 'ERRORS_GET_FAILURE',

  DOWNLOAD_REQUEST: 'ERRORS_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'ERRORS_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'ERRORS_DOWNLOAD_FAILURE',

  UPDATE_REQUEST: 'ERRORS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ERRORS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ERRORS_UPDATE_FAILURE',

  DELETE_REQUEST: 'ERRORS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ERRORS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ERRORS_DELETE_FAILURE',
};

export default errorConstants;
