import { API } from 'aws-amplify';
import * as helpers from '../../helpers/helper-functions';

const list = (conditions) => {
  const queryString = conditions ? `?${helpers.objectToQueryString(conditions)}` : '';
  return API.get('linkcodes', '/linkcodes' + queryString);
};

const get = (id) => API.get('linkcodes', `/linkcodes/${id}`);

const update = async (linkcode) => {
  return API.put('linkcodes', '/linkcodes/' + linkcode.linkcodeId, { body: linkcode });
};

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = async (linkcode) => {
  return API.del('linkcodes', `/linkcodes/${linkcode.linkcodeId}`);
};

const linkcodeService = {
  list,
  get,
  update,
  delete: _delete,
};

export default linkcodeService;
