export const daysInSeconds = (seconds) => Math.floor(seconds / (60 * 60 * 24));
export const hoursInSeconds = (seconds) => Math.floor(seconds / (60 * 60));
export const minutesInSeconds = (seconds) => Math.floor(seconds / 60);
export const secondsInSeconds = (seconds) => Math.floor(seconds % 60);

export const formatTime = (totalSeconds) => {
  if (isNaN(totalSeconds)) return null;
  const days = daysInSeconds(totalSeconds);
  const hours = hoursInSeconds(totalSeconds) % 24;
  const minutes = minutesInSeconds(totalSeconds) % 60;
  const seconds = secondsInSeconds(totalSeconds);
  const secondsString = ':' + seconds.toString().padStart(2, '0');
  const minutesString =
    days || hours || minutes ? (days || hours ? ':' + minutes.toString().padStart(2, '0') : minutes.toString()) : '0';
  const hoursString = days || hours ? (days ? ':' + hours.toString().padStart(2, '0') : hours.toString()) : '';
  const daysString = days ? days.toString() : '';
  return daysString + hoursString + minutesString + secondsString;
};

export const formatDay = (date) => {
  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleString('en-US', options);
};

export const formatBytes = (bytes) => {
  if (isNaN(bytes)) return null;
  const kb = Math.round(bytes / 1024);
  if (kb < 1024) return `${kb} KB`;
  const mb = kb / 1024;
  if (mb < 1024) return `${mb.toFixed(2)} MB`;
  const gb = mb / 1024;
  return `${gb.toFixed(2)} GB`;
};

export const objectToQueryString = (obj) =>
  Object.keys(obj)
    .map((key) => {
      const encodedKey = encodeURIComponent(key);
      const valueToEncode = typeof obj[key] === 'object' ? JSON.stringify(obj[key]) : obj[key];
      const encodedValue = encodeURIComponent(valueToEncode);
      return `${encodedKey}=${encodedValue}`;
    })
    .join('&');
