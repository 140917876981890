const unknownFileConstants = {
  LIST_REQUEST: 'UNKNOWN_FILES_LIST_REQUEST',
  LIST_SUCCESS: 'UNKNOWN_FILES_LIST_SUCCESS',
  LIST_FAILURE: 'UNKNOWN_FILES_LIST_FAILURE',

  GET_REQUEST: 'UNKNOWN_FILES_GET_REQUEST',
  GET_SUCCESS: 'UNKNOWN_FILES_GET_SUCCESS',
  GET_FAILURE: 'UNKNOWN_FILES_GET_FAILURE',

  DOWNLOAD_REQUEST: 'UNKNOWN_FILES_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'UNKNOWN_FILES_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'UNKNOWN_FILES_DOWNLOAD_FAILURE',

  UPDATE_REQUEST: 'UNKNOWN_FILES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UNKNOWN_FILES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UNKNOWN_FILES_UPDATE_FAILURE',

  DELETE_REQUEST: 'UNKNOWN_FILES_DELETE_REQUEST',
  DELETE_SUCCESS: 'UNKNOWN_FILES_DELETE_SUCCESS',
  DELETE_FAILURE: 'UNKNOWN_FILES_DELETE_FAILURE',
};

export default unknownFileConstants;
