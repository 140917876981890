import userConstants from './constants';

const initialState = {
  isListing: false,
  isDownloading: false,
  isGetting: false,
  isGettingAuth: false,
  isGettingLinkcodes: false,
  isGettingRecordings: false,
  isUpdating: false,
  isDeleting: false,
  items: null,
  lastKey: null,
  item: null,
  itemAuth: null,
  error: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LIST_REQUEST:
      return {
        ...state,
        isListing: true,
        error: null,
      };
    case userConstants.LIST_SUCCESS:
      const listItems = state.items ? state.items : [];
      action.result.items.forEach((item) => {
        const itemIndex = listItems.findIndex((listItem) => listItem.userId === item.userId);
        if (itemIndex > -1) {
          listItems[itemIndex] = { ...listItems[itemIndex], ...item };
        } else {
          listItems.push(item);
        }
      });
      return {
        ...state,
        isListing: false,
        items: listItems,
        lastKey: action.result.lastKey,
      };
    case userConstants.LIST_FAILURE:
      return {
        ...state,
        isListing: false,
        error: action.error,
      };
    case userConstants.DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloading: true,
        error: null,
      };

    case userConstants.DOWNLOAD_SUCCESS:
      return {
        ...state,
        isDownloading: false,
      };
    case userConstants.DOWNLOAD_FAILURE:
      return {
        ...state,
        isDownloading: false,
        error: action.error,
      };
    case userConstants.GET_REQUEST:
      return {
        ...state,
        isGetting: true,
        error: null,
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        isGetting: false,
        item: action.result,
      };
    case userConstants.GET_FAILURE:
      return {
        ...state,
        isGetting: false,
        user: null,
        item: action.error,
      };
    case userConstants.GET_AUTH_REQUEST:
      return {
        ...state,
        isGettingAuth: true,
        error: null,
      };
    case userConstants.GET_AUTH_SUCCESS:
      return {
        ...state,
        isGettingAuth: false,
        itemAuth: action.result,
      };
    case userConstants.GET_AUTH_FAILURE:
      return {
        ...state,
        isGettingAuth: false,
        itemAuth: null,
        error: action.error,
      };
    case userConstants.GET_LINKCODES_REQUEST:
      return {
        ...state,
        isGettingLinkcodes: true,
        error: null,
      };
    case userConstants.GET_LINKCODES_SUCCESS:
      const linkcodesItems = state.item.linkcodes && state.item.linkcodes.items ? state.item.linkcodes.items : [];
      action.result.items.forEach((item) => {
        if (!linkcodesItems.map((it) => it.linkcodeId).includes(item.linkcodeId)) linkcodesItems.push(item);
      });
      const linkcodes = {
        items: linkcodesItems,
        lastKey: action.result.lastKey,
      };
      return {
        ...state,
        isGettingLinkcodes: false,
        item: { ...state.item, linkcodes },
      };
    case userConstants.GET_LINKCODES_FAILURE:
      return {
        ...state,
        isGettingLinkcodes: false,
        error: action.error,
      };
    case userConstants.GET_RECORDINGS_REQUEST:
      return {
        ...state,
        isGettingRecordings: true,
        error: null,
      };
    case userConstants.GET_RECORDINGS_SUCCESS:
      const recordingsItems = state.item.recordings && state.item.recordings.items ? state.item.recordings.items : [];
      action.result.items.forEach((item) => {
        if (!recordingsItems.map((it) => it.recordingId).includes(item.recordingId)) recordingsItems.push(item);
      });
      const recordings = {
        items: recordingsItems,
        lastKey: action.result.lastKey,
      };
      return {
        ...state,
        isGettingRecordings: false,
        item: { ...state.item, recordings },
      };

    case userConstants.GET_RECORDINGS_FAILURE:
      return {
        ...state,
        isGettingRecordings: false,
        error: action.error,
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case userConstants.UPDATE_SUCCESS:
      const updatedItems = state.items
        ? state.items.map((item) => (item.userId === action.result.userId ? { ...item, ...action.result } : item))
        : state.items;
      return {
        ...state,
        isUpdating: false,
        items: updatedItems,
        item: { ...state.item, ...action.result },
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    case userConstants.DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case userConstants.DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        item: null,
      };
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userReducer;
