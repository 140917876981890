import appConstants from './constants';

const initialState = { breadcrumbTitle: null };

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.SET_BREADCRUMB_TITLE:
      return {
        breadcrumbTitle: action.breadcrumbTitle,
      };
    default:
      return state;
  }
};

export default appReducer;
