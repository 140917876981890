const userConstants = {
  SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

  SIGNUP_RESEND_CODE_REQUEST: 'USERS_SIGNUP_RESEND_CODE_REQUEST',
  SIGNUP_RESEND_CODE_SUCCESS: 'USERS_SIGNUP_RESEND_CODE_SUCCESS',
  SIGNUP_RESEND_CODE_FAILURE: 'USERS_SIGNUP_RESEND_CODE_FAILURE',

  SIGNUP_CONFIRMATION_REQUEST: 'USERS_SIGNUP_CONFIRMATION_REQUEST',
  SIGNUP_CONFIRMATION_SUCCESS: 'USERS_SIGNUP_CONFIRMATION_SUCCESS',
  SIGNUP_CONFIRMATION_FAILURE: 'USERS_SIGNUP_CONFIRMATION_FAILURE',

  SIGNIN_REQUEST: 'USERS_SIGNIN_REQUEST',
  SIGNIN_SUCCESS: 'USERS_SIGNIN_SUCCESS',
  SIGNIN_CHALLENGE: 'USERS_SIGNIN_CHALLENGE',
  SIGNIN_FAILURE: 'USERS_SIGNIN_FAILURE',

  SIGNOUT_REQUEST: 'USERS_SIGNOUT_REQUEST',
  SIGNOUT_SUCCESS: 'USERS_SIGNOUT_SUCCESS',
  SIGNOUT_FAILURE: 'USERS_SIGNOUT_FAILURE',

  CURRENT_SESSION_REQUEST: 'USERS_CURRENT_SESSION_REQUEST',
  CURRENT_SESSION_SUCCESS: 'USERS_CURRENT_SESSION_SUCCESS',
  CURRENT_SESSION_FAILURE: 'USERS_CURRENT_SESSION_FAILURE',

  FORGOT_PASSWORD_CODE_REQUEST: 'USERS_FORGOT_PASSWORD_CODE_REQUEST',
  FORGOT_PASSWORD_CODE_SUCCESS: 'USERS_FORGOT_PASSWORD_CODE_SUCCESS',
  FORGOT_PASSWORD_CODE_FAILURE: 'USERS_FORGOT_PASSWORD_CODE_FAILURE',

  FORGOT_PASSWORD_RESEND_CODE_REQUEST: 'USERS_FORGOT_PASSWORD_RESEND_CODE_REQUEST',
  FORGOT_PASSWORD_RESEND_CODE_SUCCESS: 'USERS_FORGOT_PASSWORD_RESEND_CODE_SUCCESS',
  FORGOT_PASSWORD_RESEND_CODE_FAILURE: 'USERS_FORGOT_PASSWORD_RESEND_CODE_FAILURE',

  FORGOT_PASSWORD_UPDATE_REQUEST: 'USERS_FORGOT_PASSWORD_UPDATE_REQUEST',
  FORGOT_PASSWORD_UPDATE_SUCCESS: 'USERS_FORGOT_PASSWORD_UPDATE_SUCCESS',
  FORGOT_PASSWORD_UPDATE_FAILURE: 'USERS_FORGOT_PASSWORD_UPDATE_FAILURE',

  FORGOT_PASSWORD_RESET: 'USERS_FORGOT_PASSWORD_RESET',

  LIST_REQUEST: 'USERS_LIST_REQUEST',
  LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  LIST_FAILURE: 'USERS_LIST_FAILURE',

  DOWNLOAD_REQUEST: 'USERS_DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'USERS_DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'USERS_DOWNLOAD_FAILURE',

  GET_REQUEST: 'USERS_GET_REQUEST',
  GET_SUCCESS: 'USERS_GET_SUCCESS',
  GET_FAILURE: 'USERS_GET_FAILURE',

  GET_AUTH_REQUEST: 'USERS_GET_AUTH_REQUEST',
  GET_AUTH_SUCCESS: 'USERS_GET_AUTH_SUCCESS',
  GET_AUTH_FAILURE: 'USERS_GET_AUTH_FAILURE',

  GET_LINKCODES_REQUEST: 'USERS_GET_LINKCODES_REQUEST',
  GET_LINKCODES_SUCCESS: 'USERS_GET_LINKCODES_SUCCESS',
  GET_LINKCODES_FAILURE: 'USERS_GET_LINKCODES_FAILURE',

  GET_RECORDINGS_REQUEST: 'USERS_GET_RECORDINGS_REQUEST',
  GET_RECORDINGS_SUCCESS: 'USERS_GET_RECORDINGS_SUCCESS',
  GET_RECORDINGS_FAILURE: 'USERS_GET_RECORDINGS_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  UPDATE_EMAIL_REQUEST: 'USERS_UPDATE_EMAIL_REQUEST',
  UPDATE_EMAIL_SUCCESS: 'USERS_UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'USERS_UPDATE_EMAIL_FAILURE',

  UPDATE_EMAIL_RESEND_CODE_REQUEST: 'USERS_UPDATE_EMAIL_RESEND_CODE_REQUEST',
  UPDATE_EMAIL_RESEND_CODE_SUCCESS: 'USERS_UPDATE_EMAIL_RESEND_CODE_SUCCESS',
  UPDATE_EMAIL_RESEND_CODE_FAILURE: 'USERS_UPDATE_EMAIL_RESEND_CODE_FAILURE',

  UPDATE_EMAIL_CONFIRMATION_REQUEST: 'USERS_UPDATE_EMAIL_CONFIRMATION_REQUEST',
  UPDATE_EMAIL_CONFIRMATION_SUCCESS: 'USERS_UPDATE_EMAIL_CONFIRMATION_SUCCESS',
  UPDATE_EMAIL_CONFIRMATION_FAILURE: 'USERS_UPDATE_EMAIL_CONFIRMATION_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'USERS_UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'USERS_UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'USERS_UPDATE_PASSWORD_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',
};
export default userConstants;
